import React, {FunctionComponent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  AppBar,
  Tabs,
  Tab} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core/styles";

import NewsBanner from './NewsBanner';
import TabPanel from './TabPanel';
import ColorPalette from '../theme/ColorPalette';
import NewsAccordion from './NewsAccordion';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: ColorPalette.monochrome.grey10,
    '& .MuiTab-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    }
  },
  tabContent: {
    padding: theme.spacing(2)
  }
}))

interface ILUSettings {
  getJWT: () => Promise<string | undefined | null>
}

const LUSettings: FunctionComponent<ILUSettings> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
      <>
        <Box mt={2}>
          <Typography variant="h1" component="h2" className="m-4">
            {t('title')}
          </Typography>
        </Box>
        <Box className={classes.tabs}>
          <AppBar position="static" color="default" elevation={1}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={classes.tabs}
            >
                <Tab
                    label={t('tabs.banner')}
                    id="scrollable-auto-tab-0"
                    aria-controls="scrollable-auto-tabpanel-0"
                />
                <Tab
                    label={t('tabs.accordion')}
                    id="scrollable-auto-tab-1"
                    aria-controls="scrollable-auto-tabpanel-1"
                />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
              <NewsBanner getJWT={props.getJWT} />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <NewsAccordion getJWT={props.getJWT} />
          </TabPanel>
        </Box>
      </>
  );
};

export default LUSettings;