import config from '../config'
import axios, { Method } from 'axios';

export const getApiService = (getJWT: () => Promise<string | undefined | null>) => {

  const updateSettings = async (requestData: any) => {
    try {
      const endpoint = 'put-settings'
      const path = `/${config.API_PATH_PREFIX}/${endpoint}`
      const host = config.API_URL.replace('https://', '')
      const method: Method = 'PUT'
      const options = {
        host,
        path,
        url: `${config.API_URL}${path}`,
        headers: {
          ...({'Content-Type': 'application/json'}),
          authorization: await getJWT(),
        } as Record<string, string>,
        method,
        body: JSON.stringify(requestData),
        data: requestData,
      };

      return ( await axios(options) ).data
    } catch (error) {
      console.log('=== api request error ===', error)
      return requestData
    }
  };

  const getSettings = async (pk: string) => {
    try {
      const endpoint = 'get-settings'
      const path = `/${config.API_PATH_PREFIX}/${endpoint}?pk=${pk}`
      const host = config.API_URL.replace('https://', '')
      const method: Method = 'GET'
      const options = {
        host,
        path,
        url: `${config.API_URL}${path}`,
        headers: {
          authorization: await getJWT(),
        } as Record<string, string>,
        method
      };

      return ( await axios(options) ).data
    } catch (error) {
      console.log('=== api request error ===', error)
      return error
    }
  };


  return {
    updateSettings,
    getSettings
  }
}