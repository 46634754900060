import React, {FunctionComponent, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, 
  Checkbox, 
  FormControlLabel,
  Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core/styles";
import { INews } from '../typings/news';

import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2)
  },
  newsForm: {
    '& .luSettings-MuiFormControl-root': {
      marginBottom: theme.spacing(2)
    },
  },
  colorPicker: {
    marginBottom: theme.spacing(2),
    marginRight: '40px',
    '& .sample': {
      position: 'absolute',
      right: '-40px',
      bottom: 0,
      width: 24, 
      height: 24,
      marginLeft: '.5rem'
    }
  },
  editor: {
    borderBottom: '1px solid #999',
    marginBottom: '1.5rem'
  }
}))

interface INewsAccordionForm {
  index: number
  news?: INews
  onSubmit: (...args: any) => void
  onModalClose: (...args: any) => void
}

const NewsAccordionForm: FunctionComponent<INewsAccordionForm> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [title, setTitle] = useState<string | undefined>(props.news?.title)
  const [content, setContent] = useState<string | undefined>(props.news?.content)
  const [ctaLabel, setCtaLabel] = useState<string | undefined>(props.news?.cta?.label)
  const [ctaUrl, setCtaUrl] = useState<string | undefined>(props.news?.cta?.url)
  const [active, setActive] = useState<boolean>(props.news?.active || false)
  const [isNew, setIsNew] = useState<boolean>(props.news?.isNew || false)
  const [isDefaultOpen, setIsDefaultOpen] = useState<boolean>(props.news?.isDefaultOpen || false)
  const [cta, setCta] = useState<boolean>(!!props.news?.cta || false)
  const [tooltip, setTooltip] = useState<boolean>(!!props.news?.tooltip || false)
  const [tooltipText, setTooltipText] = useState<string | undefined>(props.news?.tooltip?.text)

  const [editorState, setEditorState] = useState(
    () => {
      const blocksFromHTML = convertFromHTML(props.news?.content || '');
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return EditorState.createWithContent(state)
    }
  );

  useEffect(()=>{
    const rawContentState = convertToRaw(editorState.getCurrentContent()); 
    setContent(draftToHtml(rawContentState))
  }, [editorState])
  
  const handleSubmit = () => {
    props.onSubmit(props.index, {
      ...props.news,
      title,
      content,
      active,
      isNew,
      isDefaultOpen,
      cta: cta ? {
        ...props.news?.cta,
        label: ctaLabel,
        url: ctaUrl
      } : null,
      tooltip: tooltip ? {
        ...props.news?.tooltip,
        text: tooltipText
      } : null
    })
  }

  return (
    <Box style={{minWidth: 800}} p={2} pt={2} pb={2} display='flex' flexDirection="column" className={classes.newsForm}>
      <Box pb={1} display='flex' justifyContent='end'>
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={value => setActive(value.target.checked)}
              name="active"
              color="primary"
              value={active}
            />
          }
          label={t('accordionForm.activated')}
        />
      </Box>

      <TextField id="title" label={t('accordionForm.title')} defaultValue={title} onChange={value => setTitle(value.currentTarget.value)} />

      <Editor
        editorState={editorState}
        wrapperClassName={classes.editor}
        onEditorStateChange={setEditorState}
        placeholder={t('accordionForm.content')}
        toolbar={{
          options: [
            'inline', 
            // 'blockType',
            // 'fontSize', 
            // 'fontFamily', 
            'list', 
            'textAlign', 
            // 'colorPicker', 
            'link', 
            // 'embedded',
            // 'emoji', 
            // 'image', 
            'remove', 
            'history'
          ]
        }}
      />

      <Box pb={3} display='flex' justifyContent='start' flexDirection={'column'}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isDefaultOpen}
              onChange={value => setIsDefaultOpen(value.target.checked)}
              name="isDefaultOpen"
              color="primary"
              value={isDefaultOpen}
            />
          }
          label={t('accordionForm.openByDefault')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isNew}
              onChange={value => setIsNew(value.target.checked)}
              name="isNew"
              color="primary"
              value={isNew}
            />
          }
          label={t('accordionForm.newLabel')}
        />
      </Box>

      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{marginBottom: '20px'}}
              control={
                <Checkbox
                  checked={cta}
                  onChange={value => setCta(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={cta}
                />
              }
              label={t('accordionForm.cta')}
            />

            <TextField id="ctaLabel" disabled={!cta} label={t('accordionForm.ctaLabel')} defaultValue={ctaLabel} onChange={value => setCtaLabel(value.currentTarget.value)} />
            <TextField id="ctaUrl" disabled={!cta} label={t('accordionForm.ctaUrl')} defaultValue={ctaUrl} onChange={value => setCtaUrl(value.currentTarget.value)} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{marginBottom: '20px'}}
              control={
                <Checkbox
                  checked={tooltip}
                  onChange={value => setTooltip(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={tooltip}
                />
              }
              label={t('accordionForm.tooltip')}
            />

            <TextField id="tooltipText" 
              multiline={true} 
              disabled={!tooltip} 
              label={t('accordionForm.tooltipText')} 
              defaultValue={tooltipText} 
              onChange={value => setTooltipText(value.currentTarget.value)} />
          </Box>
        </Grid>
      </Grid>

      <Box pt={3} display='flex' justifyContent='end'>
        <Button variant="outlined" color="primary" component="button" style={{marginRight: '15px'}} onClick={() => props.onModalClose()}>
          {t('cancel')}
        </Button>
        <Button variant="contained" color="secondary" component="button" onClick={handleSubmit}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default NewsAccordionForm;