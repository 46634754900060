export const i18n_de = {
  title: 'LU Settings',
  titleNewsBanner: 'News Banner einrichten',
  form: {
    enabled: 'Aktiviert', 
    text: 'Text',
    textPlaceholder: 'Bitte Text einfügen',
    link: 'Link',
    linkPlaceholder: 'Bitte Link einfügen',
    color: 'Farbe'
  },
  save: 'Speichern',
  successAlert: 'Erfolgreich gespeichert',
  failAlert: 'Speichern fehlgeschlagen',
  confirmationDeleteMsg: 'Eintrag wirklich löschen?',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  deactivated: 'Deaktiviert',
  new: 'Neu',
  tabs: {
    banner: 'News Banner',
    accordion: 'Aktuelle Meldungen'
  },
  accordionForm: {
    activated: 'Aktiviert',
    title: 'Titel',
    content: 'Inhalt',
    openByDefault: 'immer ausgeklappt',
    newLabel: '"neu" - Label',
    cta: 'Aktions - Button',
    ctaLabel: 'Buttontitel',
    ctaUrl: 'Url',
    tooltip: 'Tooltip',
    tooltipText: 'Text'
  },
  formatDate: 'dd.MM.yyyy'
}
